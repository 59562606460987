@import '../../style/variables.scss';

.contact {

    @media screen and (max-width: 767px) {
        &__img {
            display: none;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        &__img {
            width: 200px;
            margin: auto;
        }
    }
}



form {
    margin: auto;
    color: $primary-color;
    font-size: 12px;

    @media screen and (min-width: 768px) {
        margin: 0;
        font-size: 14px;
        width: 50%;
    }

}

input,
textarea {
    margin: 7px 0;
    width: 100%;
    border-style: none;
    background-color: transparent;
}

label {
    // width: 70px;
    padding-left: 10px;
    white-space: nowrap;
}

.contact__form {

    &__message,
    &__user-name,
    &__user-email {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: rgba($primary-color, 0.1);
        border-radius: 10px;
        margin: 10px 0;
    }

    &__message {
        padding: 5px 0 30px;
        margin-bottom: 20px;
        align-items: flex-start;
        position: relative;
    }
}

.contact__form__send-button {
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    width: fit-content;
    bottom: 0;
    right: 0;
    translate: -10px -10px;
    margin: 0;

    &.button-primaryColor {
        border: $primary-color solid 1px;
        background: linear-gradient(150deg, rgba($primary-color, 0.2665441176470589) 68%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 78%, rgba(97, 58, 231, 0.23012955182072825) 85%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 91%, rgba(97, 58, 231, 0.23012955182072825) 95%);

        &:hover {
            background: linear-gradient(150deg, rgba($primary-color, 0.49) 68%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 78%, rgba(97, 58, 231, 0.49) 85%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 91%, rgba(97, 58, 231, 0.49) 95%);
        }

        &:active {
            background: rgba($primary-color, 0.49);
        }

    }
}