$primary-color: #613AE7;
$secondary-color: #ffffff;
$gradient: linear-gradient(180deg, rgba($primary-color, 0.089) 0%, rgba(255, 255, 255, 0) 100%);
$fonts: Poppins, Arial, Helvetica, sans-serif;
$gridItems : 0 1 1,
1 1 2,
2 1 3,
3 1 4,
4 2 1,
5 2 2,
6 2 3,
7 2 4,
8 3 1,
9 3 2,
10 3 3,
11 3 4,
12 4 1,
13 4 2,
14 4 3,
15 4 4;
$darkMode-size: 30px;
$darkMode__label-height: $darkMode-size;
$darkMode__labelCursor-initialPosition: calc($darkMode-size * 0.05);
$darkMode__labelCursor-size: calc($darkMode-size * 0.9);
$darkMode__label-width: calc(($darkMode__labelCursor-size * 2.5) + ($darkMode__labelCursor-initialPosition * 2));
$darkMode__labelCursor-finalPosition: calc($darkMode__labelCursor-size * 1.5 + $darkMode__labelCursor-initialPosition);
$darkMode__checkedLabelCursor-finalPosition: calc($darkMode__labelCursor-size * 1.5 + $darkMode__labelCursor-initialPosition - $darkMode__labelCursor-size * 0.3);
$darkMode__activeLabel-width: calc($darkMode__labelCursor-size * 1.3);
$darkMode__Label-initialOrigin: calc($darkMode__labelCursor-size / 2 * -1);
$darkMode__Label-finalOrigin: calc($darkMode__labelCursor-size / 2);