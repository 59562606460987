@import "../../style/variables.scss";

.skills__skillView {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: -3;

    &-view {
        width: 100%;
        height: 100%;
        opacity: inherit;
        z-index: inherit;
        transition: opacity 200ms 500ms;
    }

    &-title {
        position: absolute;
        top: 5px;
        left: 10px;
        margin: 0;
        color: $primary-color;
        opacity: inherit;
        transition: opacity 200ms 600ms;
        font-size: 3.5vw;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 75%);

        @media screen and (min-width: 426px) {
            font-size: 30px;
            top: 0px;
            left: 20px;
        }
    }

    &-close {
        position: absolute;
        top: 5px;
        right: 10px;
        margin: 3px 0;
        padding: 1px;
        color: $primary-color;
        background: radial-gradient(circle, rgba(255, 255, 255, 1) 10%, rgba(97, 58, 231, 0) 80%);
        border-radius: 50%;
        opacity: inherit;
        font-size: 3.5vw;
        z-index: inherit;
        transition: opacity 200ms 600ms;

        @media screen and (min-width: 426px) {
            font-size: 30px;
            top: 1px;
            right: 20px;
        }

    }

    & video {
        background-color: white;

        &::after {
            content: "comming soon...";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.showSkillView {
        opacity: inherit;
        z-index: 2;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-color;
            opacity: 0;
            z-index: -1;

            @keyframes appear {
                0% {
                    opacity: 0;
                    scale: 0;
                    border-radius: 100%;
                    z-index: 1;
                }

                80% {
                    opacity: 1;
                    scale: 1;
                    border-radius: 0%;
                    background-color: $primary-color;
                }

                100% {
                    scale: 1;
                    border-radius: 0%;
                    background-color: transparent;
                    opacity: 1;
                }
            }

            animation: appear 1000ms cubic-bezier(.29, 1.02, .83, 1) 0ms forwards;
        }

    }

}