@import '../../style/variables.scss';

button {
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;

    &.button-primaryColor {
        border: $primary-color solid 1px;
        background: linear-gradient(150deg, rgba($primary-color, 0.2665441176470589) 68%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 78%, rgba(97, 58, 231, 0.23012955182072825) 85%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 91%, rgba(97, 58, 231, 0.23012955182072825) 95%);

        &:hover {
            background: linear-gradient(150deg, rgba($primary-color, 0.49) 68%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 78%, rgba(97, 58, 231, 0.49) 85%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 91%, rgba(97, 58, 231, 0.49) 95%);
        }

        &:active {
            background: rgba($primary-color, 0.49);
        }

        @media screen and (min-width: 768px) {
            font-size: 16px;
        }

    }

    &.realisations__buttons-button {
        margin: 10px 0 0 10px;
    }

    &.form__send-button {
        position: absolute;
        bottom: 0;
        right: 0;
        translate: -10px -10px;
        margin: 0;
    }
}