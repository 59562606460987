@import "../../style/variables.scss";

.home {
    &__nav-bar {
        background-color: white;
        border: solid rgba($primary-color, 0.5) 1px;
        z-index: 3;

        & a:hover {
            font-weight: 800;
            font-size: 110%;
        }

        @media screen and (max-width: 767px) {
            position: fixed;
            top: 20px;
            right: 0;
            padding: 5px 10px;
            border-radius: 0 0 0 10px;

            @keyframes translate {
                0% {
                    translate: 110% 0;
                }

                100% {
                    translate: 0 0;
                }
            }

            animation: translate 300ms ease-out forwards;

            &.hide {
                @keyframes hide {
                    0% {
                        translate: 0 0;
                    }

                    100% {
                        translate: 110% 0;
                    }
                }

                animation: hide 300ms ease-out forwards;
            }

            &__nav {
                font-weight: 700;
                padding: 5px 0;
            }

            &__close {
                position: absolute;
                top: 0;
                left: 0;
                translate: -40% -40%;
                padding: 3px 7px;
                background-color: white;
                border-radius: 50%;
                font-size: 18px;
                z-index: 4;
                border: solid $primary-color 1px;

                &::after {
                    content: "";
                    position: absolute;
                    left: 40%;
                    right: -2px;
                    top: 38%;
                    bottom: -2px;
                    z-index: -1;
                    border: solid white 8px;
                }
            }

            & ul {
                padding-top: 2px;
            }
        }

        @media screen and (min-width: 768px) {
            width: 100%;
            border: solid rgba($primary-color, 0.5);
            border-left: 0;
            border-right: 0;
            padding: 5px 0;

            &.sticky {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
            }

            & ul {
                display: flex;
                justify-content: space-around;
                font-size: 18px;
                font-weight: 500;
            }

            &__close {
                display: none;
            }
        }
    }
}

#home {
    label {
        width: $darkMode__label-width;
        height: $darkMode__label-height;
        position: relative;
        // position: absolute;
        // right: 20px;
        // top: 20px;
        display: block;
        background: #ebebeb;
        border-radius: 200px;
        box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
        cursor: pointer;

        &:after {
            content: "";
            width: $darkMode__labelCursor-size;
            height: $darkMode__labelCursor-size;
            position: absolute;
            top: $darkMode__labelCursor-initialPosition;
            left: $darkMode__labelCursor-initialPosition;
            background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(97, 58, 231, 1) 60%, rgba(0, 0, 0, 1) 98%);
            border-radius: 180px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }

        &:hover:after {
            width: $darkMode__activeLabel-width;
            transform-origin: $darkMode__Label-initialOrigin;
        }
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        visibility: hidden;
    }

    input:checked+label {
        background: #242424;
    }

    input:checked+label:after {
        left: auto;
        transform: translateX($darkMode__labelCursor-finalPosition);
        background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(97, 58, 231, 1) 68%);
    }

    input:checked+label:hover:after {
        width: $darkMode__activeLabel-width;
        transform: translateX($darkMode__checkedLabelCursor-finalPosition);
    }

    label,
    label:after {
        transition: 0.3s
    }
}

input:checked>body {
    background-color: #242424;
    color: white;
}