@import '../../style/variables.scss';

.skills__grid {
    position: relative;
    margin: 20px auto 25px;
    padding: 20px;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    grid-auto-rows: minmax(15vw, auto);
    min-height: 38vw;
    width: 70vw;
    background-color: rgba($primary-color, 0.04);
    z-index: 2;
    opacity: 1;

    &::before {
        content: "";
        position: absolute;
        top: 2.5%;
        left: 2.5%;
        height: 95%;
        width: 95%;
        background-color: white;
        z-index: -1;
    }

    &.transparent {
        opacity: 0;
    }

    &-mask {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        left: -5px;
        background-color: rgb(255, 255, 255);
        height: 100%;
        width: 100%;
        font-size: 20px;
        font-weight: 900;
        color: $primary-color;
        border: outset 10px rgba($primary-color, 0.1);
    }

    @media screen and (min-width: 426px) {
        height: 38vw;
        // height: fit-content;
        width: 70vw;
        display: flex;
        justify-content: space-around;
        // align-items: left;
        margin: 25px auto 50px;
        // background-color: rgba(aqua, 0.5);

        &::before {
            content: "";
            display: flex;
            justify-content: right;
            align-items: center;
            padding: 0px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;
            opacity: 0;
            z-index: -1;
        }

        &-skills {
            width: inherit;
            display: flex;
        }
    }
}