@import '../../style/variables.scss';
@import '../../style/keyframes.scss';


.skills__grid {

    &-item {
        // background-color: rgb(0, 255, 255, 0.4);
        // border: solid 2px aqua;
        display: flex;
        // justify-content: center;
        // align-items: center;
        height: fit-content;

        button {
            white-space: nowrap;
            overflow: visible;
            font-weight: 600;
            font-size: 3vw;
        }

        @media screen and (max-width: 425px) {

            @each $gridItem,
            $row,
            $column in $gridItems {
                &.n#{$gridItem} {
                    grid-column: #{$column};
                    grid-row: #{$row};
                    transform: rotateZ(-30 + (random(60) * 1deg));
                    scale: 0;

                    &.appear {
                        $randomDelay: 400+(random(10) * 100);
                        animation: scaleUp 300ms cubic-bezier(0, .2, .88, .63) #{$randomDelay+ms} forwards;
                    }
                }
            }
        }

        @media screen and (min-width: 426px) {

            button {
                font-weight: 700;
                font-size: 18px;
                margin-top: 50px;
            }
        }
    }

    @media screen and (min-width: 426px) {
        display: flex;
    }
}