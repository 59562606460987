@import '../../style/variables.scss';

#home {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        flex-direction: row;
        height: 40vw;

        &.shadow::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000, 0.5);
            z-index: 3;
        }
    }
}

.home {
    &__banner {
        width: 100%;
        object-fit: cover;
    }

    &__title {
        position: absolute;
        top: 10px;
        left: 10px;
        margin: 0;
        font-size: 18px;

        @media screen and (min-width: 768px) {
            top: 3vw;
            left: 4vw;
            font-size: 34px;
        }
    }

    &__menu-button {
        position: fixed;
        top: 10px;
        right: 10px;
        background-color: white !important;
        border-radius: 3px;
        border: 4px outset $gradient;
        z-index: 3;
    }
}

#home {
    // label {
    //     width: $darkMode__label-width;
    //     height: $darkMode__label-height;
    //     position: absolute;
    //     right: 20px;
    //     top: 20px;
    //     display: block;
    //     padding-top: 0;
    //     margin-top: 0;
    //     background: #ebebeb;
    //     border-radius: 200px;
    //     box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    //     cursor: pointer;

    //     &:after {
    //         content: "";
    //         width: $darkMode__labelCursor-size;
    //         height: $darkMode__labelCursor-size;
    //         position: absolute;
    //         top: $darkMode__labelCursor-initialPosition;
    //         left: $darkMode__labelCursor-initialPosition;
    //         background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(97, 58, 231, 1) 60%, rgba(0, 0, 0, 1) 98%);
    //         border-radius: 180px;
    //         box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    //     }

    //     &:hover:after {
    //         width: $darkMode__activeLabel-width;
    //         transform-origin: $darkMode__Label-initialOrigin;
    //     }
    // }

    // input {
    //     position: absolute;
    //     width: 0;
    //     height: 0;
    //     visibility: hidden;
    // }

    // input:checked+label {
    //     background: #242424;
    // }

    // input:checked+label:after {
    //     left: auto;
    //     transform: translateX($darkMode__labelCursor-finalPosition);
    //     background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(97, 58, 231, 1) 68%);
    // }

    // input:checked+label:hover:after {
    //     width: $darkMode__activeLabel-width;
    //     transform: translateX($darkMode__checkedLabelCursor-finalPosition);
    // }

    // label,
    // label:after {
    //     transition: 0.3s
    // }
}