@import '../../style/variables.scss';

section {
    padding: 0 8vw;
    background: $gradient;

    .section__logo {
        position: absolute;
        margin: 5px;
        top: 0;
        left: 0;
        width: 5px;
    }

    h2 {
        position: relative;
        margin: auto;
        padding: 15px;
        padding-bottom: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        // font-size: 5vw;

        @media screen and (min-width: 768px) {
            font-weight: 700;
            font-size: 30px;
            padding: 35px;
            padding-bottom: 25px;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            vertical-align: middle;
            transform: translateY(-4%);
            font-size: 40px;
            // font-size: 10vw;
            font-weight: 700;
            opacity: 4%;

            @media screen and (min-width: 768px) {
                font-size: 58px;
                transform: translateY(16%);
            }
        }
    }

    p {
        font-size: 12px;
        text-align: center;
    }
}