footer {
    height: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    @media screen and (min-width: 767px) {
        height: 200px;
        gap: 30px;
    }

    img {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    p {
        font-size: 12px;
        margin: 0 20px;
        padding-bottom: 5px;
        text-align: center;

        @media screen and (min-width: 767px) {
            font-size: 12px;
        }
    }

    .footer__links {
        display: flex;
        justify-content: center;
        gap: 25px;
        padding-top: 5px;

        @media screen and (min-width: 767px) {
            font-size: 35px;
            gap: 85px;
        }
    }
}