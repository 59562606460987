@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
@import "./style/variables.scss";

html {
    scroll-behavior: smooth;
}

a {
    color: #000000;
    text-decoration: none;
}

body {
    scroll-behavior: smooth;
    font-family: $fonts;
    font-size: 14px;
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}